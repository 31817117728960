@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Poppins:wght@400;500;600;800&family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.user_background {
  /* background-image: url("https://kistpay-customer-data.s3.me-south-1.amazonaws.com/033255520001697544428117-background.jpg"); */
  /* background-size: 100% 100%; */
  /* height: 30vh; */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.right-aligned{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-margin{
  margin-left: 1rem;
}
.user_background .userImage {
  border-radius: 5%;
}

.user_background .userImage img {
  width: 100%;
  height: 100%;
  border-radius: 5%;
}

.infoCard {
  position: fixed;
  /* top: -50px; */
}

.fade-in {
  -webkit-animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bck 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-bck {
  0% {
    -webkit-transform: translateZ(80px);
    transform: translateZ(80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.fade-out {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

:where(.css-dev-only-do-not-override-1i5zyo0).ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-inline-start: 0px !important;
}

:where(.css-1i5zyo0).ant-checkbox-wrapper+.ant-checkbox-wrapper {
  margin-inline-start: 0px !important;
}

.status-stepper-container {
  width: 100%;
  /* Adjust this as needed */
  overflow: auto;
  /* Adds scrollbar if content is too wide */
  padding: 10px;
  /* Optional, for some spacing */
}


.ant-pagination-options {
  display: none !important;
}

.card_product {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 1rem auto;
  overflow: hidden;
  padding: 1rem;
  width: 80%;
  max-width: 600px;
  background-color: #fff;
}

.otp-input {
  text-align: center;
}

.oval1 {
  position: absolute;
  right: -40px;

  background-color: #bbffa836;
  filter: blur(55.325443267822266px);
  width: 60%;
  height: 100%;
  border-radius: 100%;
  top: -40px;
  z-index: -1;

}

.oval2 {
  position: absolute;
  left: -40px;

  background-color: #ffe2ee99;
  filter: blur(55.325443267822266px);
  width: 100%;
  height: 50%;
  border-radius: 100%;
  bottom: -40px;
  z-index: -1;
}

.oval3 {
  position: absolute;
  left: -40px;

  background-color: #fbaaff47;
  filter: blur(55.325443267822266px);
  width: 50%;
  height: 50%;
  border-radius: 100%;
  top: -40px;
  z-index: -1;
}

.oval-background {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  /* background-color: rgba(217, 255, 244, 0.75); */
  border-radius: 12px;
  border: 1px solid rgba(217, 216, 216, 0.727);
  overflow: hidden;
}

.banner_img img {
  width: 100%;
  height: 100%;
}

#tnc>div:nth-child(2)>div:nth-child(4)>div {
  margin-bottom: 0px !important;
  height: 100% !important;
}


.ql-container.ql-snow {
  height: auto;
  border-radius: 0px 0px 5px 5px;
}

.ql-editor {
  height: 250px !important;
  overflow-y: scroll;
  border-radius: 10px !important;
}

.ql-toolbar.ql-snow {
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 0.25px 0.75px 0px #00000005;
  box-shadow: 0px 0.6px 1.8px 0px #00000007;
  box-shadow: 0px 1.13px 3.38px 0px #00000009;
  box-shadow: 0px 2.01px 6.03px 0px #0000000B;
  box-shadow: 0px 3.76px 11.28px 0px #0000000D;
  box-shadow: 0px 9px 27px 0px #00000012;
  background-color: white;
}

.product-form-input {
  border-color: #CCCCCC;
  border-radius: 3px;

}

.custom-select .ant-select-selector {
  border-color: #CCCCCC !important;
  border-radius: 3px !important;

}


#root>div>section>section>section>div>main>div>form>div.ant-row>div.ant-col.ant-col-16>div:nth-child(3)>div.ant-card-body>span>div.ant-upload.ant-upload-select {
  width: 100% !important;
}

.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 50px !important;
  height: 50px !important;
  margin-bottom: 0px !important;
}

.planTable {
  width: 100%;
  border-collapse: collapse;
}

.planTable thead {
  background-color: #fbfcfc;
  width: 100%;
  padding: 2rem !important;
  color: #455560;
  border-radius: 1rem;

}

.planTable>thead>tr>th {
  height: 2rem;
  text-align: left;
  padding: 1rem 1rem 1rem 1rem;

}

.planTable>tbody>tr>td {
  padding: 1rem 1rem 1rem 1rem;
}

/* .planTable tbody tr:nth-child(even) {
  background-color: #f2f2f2;

} */

.planTable tbody tr:hover {
  background-color: #e0e0e02b;
  /* Example hover effect */
}

.groupName {
  background-color: #ebf1fe;
  color: #1a3353;
  padding: 1rem;
  /* 1rem 5rem 1rem 2rem; */
  text-align: left;

}

.groupName_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/* ===============>>>>>>>>>>>>>>>>>>>>>>Store <<<<<<<<<<<<=========================== */
.site-card-wrapper {
  padding: 20px;
}

.store_card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  text-align: center;
  padding: 20px;
}

.store_image_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-bottom: 16px;
}

.store_image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.product_name {
  margin: 16px 0 8px;
}

.product_installment {
  display: block;
  color: #888;
  margin-bottom: 8px;
}

.product_price {
  margin-bottom: 16px;
}

.order_button {
  width: 100%;
  background-color: #1890ff;
  color: #fff;
  border: none;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.order_button:hover {
  background-color: #40a9ff;
}

/* payment screens */
.payment-icon-upload-btn .ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select,
.payment-icon-upload-btn .ant-upload-wrapper.ant-upload-picture-circle-wrapper .ant-upload.ant-upload-select {
  width: 100px !important;
  height: 100px !important;
}

.table-item-edit {
  color: #001BCC;
  font-family: Inter;
  font-weight: 400;
  cursor: pointer
}