:root {
    --bold: 600;
    --bolder: 800;
    --extra-bold: 900
}

.fw-bold {
    font-weight: var(--bold);
}

.fw-bolder {
    font-weight: var(--bolder);
}

.fw-extra-bold {
    font-weight: var(--extra-bold);
}